// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bK_kz";
export var caseStudyBackground__lineColor = "bK_kq";
export var caseStudyCta__bgColor = "bK_kG";
export var caseStudyHead__imageWrapper = "bK_kn";
export var caseStudyProjectsSection = "bK_kB";
export var caseStudyQuote__bgDark = "bK_mc";
export var caseStudyQuote__bgLight = "bK_kx";
export var caseStudyQuote__bgRing = "bK_kw";
export var caseStudySolution__ring = "bK_kt";
export var caseStudySolution__ringThree = "bK_md";
export var caseStudySolution__ringTwo = "bK_kv";
export var caseStudyVideo = "bK_kD";
export var caseStudyVideo__ring = "bK_kF";
export var caseStudy__bgDark = "bK_km";
export var caseStudy__bgLight = "bK_kl";